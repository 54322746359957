import React from "react";
import css from './expandMore.module.scss';
// import {getId} from "../../../../shared/routes";
import {getRoutes} from "../../../../shared/routes";
import ExpandMoreBlackIcon from "../../../../../images/icons/expand_more_black.svg";

const ExpandMore = () => {
  return (
    <div className={css.expandMore}>
      <a href={`#${getRoutes().home.sections.getStarted}`} className={css.expandMoreLink}><img src={ExpandMoreBlackIcon} alt={''} /></a>
    </div>
  )
};

export default ExpandMore;
