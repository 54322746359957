import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {getRoutes} from '../components/shared/routes';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './index.module.scss';
import Footer from '../components/shared/Footer/Footer';
import {getImagesFluid, viewports} from "../components/componentsUtils";
import Main from "../components/pages/home/partials/main/Main";
import ExpandMore from "../components/pages/home/partials/ExpandMore/ExpandMore";
import SlideTypeM from "../components/shared/ImageSlider/SlideTypeM";
import SlideTypeN from "../components/shared/ImageSlider/SlideTypeN";
import Blockchain1 from "../images/home/Blockchain 1 mobile.jpg";
import Blockchain2 from "../images/home/Blockchain 2 mobile.jpg";
import Blockchain3 from "../images/home/Blockchain 3 mobile.jpg";
import useBreakpoint from "../components/hooks/useBreakpoint";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_home"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const IndexPage = () => {

    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const breakpoint = useBreakpoint();
    const isDesktop = () => breakpoint.viewport === viewports.desktopViewport;

    return (
        <Layout>
            <SEO/>

            <div className={css.wrapper}>

                <div className={css.Main}>
                    <Main/>
                </div>

                <div className={css.ExpandMore}>
                    <ExpandMore/>
                </div>

                <div className={css.section2} id={getRoutes().home.sections.getStarted}>
                    <SlideTypeM
                        title="Meeting the timber market technology objectives"
                        description={'Bullet proof and cost effective capability to track the timber process from the point of the forest plot, through the logging process and production, to the final products enabling the consumer to access the proof of provenance for a conscious choice of purchase.'}
                        image1={'Timber market technology objective.jpg'}
                        image1Mobile={'timber_market_technology_objective_mobile.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />
                </div>


                <div className={css.section3}>
                    <div className={css.title2}>Timber market regulations on carbon footprint</div>
                    <SlideTypeN
                        title="HWP – Harvested wood product"
                        description={(
                            <div>The timber log split impacts the carbon credit level to be granted in case
                                of the use
                                for construction and pallets. On the contrary carbon credits need to be
                                purchased for
                                the use for pulp.<br/><br/> The coming changes in the legal regulatory
                                focusing on
                                sustainable forestry, controlled and certified timber trading will
                                strengthen the
                                environmental protection. The accuracy in measurement and authentication of
                                every timber
                                log is essential in carbon credit monitoring.</div>
                        )}
                        imagesFluid={imagesFluid}
                        placeholderImage={'Timber market regulations on carbon footprint.jpg'}
                        placeholderImageAlt={'Timber market regulations on carbon footprint'}
                        line={false}
                    />
                </div>


                <div className={css.section4}>
                    <SlideTypeM
                        title="Addressing timber supply chain risk assessment"
                        image1={'Timber supply chain risk assessment.jpg'}
                        image1Mobile={'timber_supply_chain_risk_assessment_mobile.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />
                </div>
                <div className={css.text}>“The global trade in roundwood, paper, furniture and other
                    products
                    originating from illegally extracted timber is a multi-million dollar industry!”
                </div>
                <div className={css.text2}>
                    Source:
                    https://wwf.panda.org/discover/our_focus/forests_practice/deforestation_causes2/illegal_logging/
                </div>


                <div className={css.section5}>
                    <SlideTypeM
                        title="TimberFingerprint @ Chain of custody"
                        image1={'TimberFingerprint @ Chain of custody.jpg'}
                        image1Mobile={'timberfingerprint___chain_of_custody_mobile.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />
                </div>


                <div className={css.section6}>
                    {isDesktop() && <SlideTypeM
                        title="Blockchain"
                        description={'The Deeplai timber industry blockchain will provide total data visibility and a single source of truth. Within the entire networks of a supply chain based on image sourcing files can document updates to a single shared ledger.The transactions are always time-stamped and up to date, companies can query a product’s status and location at any point in time.'}
                        image1={'Blockchain.jpg'}
                        image1Mobile={'Blockchain.jpg'}
                        image1Type={'pixel'}
                        imagesFluid={imagesFluid}
                    />}
                    {isDesktop() && <div className={css.description}>This helps to combat issues like counterfeit goods,
                        compliance
                        violations, auditing and provenance certification. Companies can also share
                        track and trace
                        data with their customers as a way to verify product authenticity, sustainable
                        sourcing and
                        ethical supply chain practices.
                    </div>}

                    {!isDesktop() && <>
                        <div className={css.title}>
                            Blockchain
                        </div>
                        <div className={css.description}>The Deeplai timber industry blockchain will provide total data
                            visibility and a single source of truth.
                        </div>
                        <div className={css.imageWrapper2}>
                            <img src={Blockchain1} alt={'Blockchain1'}/>
                        </div>
                        <div className={css.description}>Within the entire networks of a supply chain based on image
                            sourcing files can document updates to a single shared ledger.The transactions are always
                            time-stamped and up to date, companies can query a product’s status and location at any
                            point in
                            time.
                        </div>
                        <div className={css.imageWrapper2}>
                            <img src={Blockchain2} alt={'Blockchain2'}/>
                        </div>
                        <div className={css.description}>This helps to combat issues like counterfeit goods,
                            compliance
                            violations, auditing and provenance certification. Companies can also share
                            track and trace
                            data with their customers as a way to verify product authenticity, sustainable
                            sourcing and
                            ethical supply chain practices.
                        </div>
                        <div className={css.imageWrapper2}>
                            <img src={Blockchain3} alt={'Blockchain3'}/>
                        </div>
                    </>}
                </div>

                <div className={css.Footer}>
                    <Footer/>
                </div>

            </div>
        </Layout>
    );
};

export default IndexPage;
