import React from "react";
import css from './main.module.scss';
import MainImage from "../../../../../images/home/main/home_1.svg";
import ProductFingerprintLogo from '../../../../../images/logos/product_fingerprint_logo.svg';
import {useTranslation} from "react-i18next";
import GoToButton from "../../../../shared/GoToButton/GoToButton";
import {getLink, getRoutes} from "../../../../shared/routes";

const Main = () => {
    const {t} = useTranslation();
    return (
        <div className={css.wrapper}>
            <div className={css.contentWrapper}>
                <div className={css.textWrapper}>
                    <img src={ProductFingerprintLogo} alt="deeplai product fingerprint logo"
                         className={css.ProductFingerprintLogo}/>
                    <div className={css.title}>
                    </div>
                    <div className={css.text}><p>Product Provenance Authentication<br/> Supply Chain Traceability
                        Compliance
                        <br/> Sustainability Management</p></div>
                    <GoToButton
                        sectionUrl={getLink([getRoutes().home, getRoutes().home.sections.getStarted])}
                        text="Get started!"
                    />
                </div>
                <div className={css.imageWrapper}>
                    <img src={MainImage} alt='Traceability Authentication'/>
                </div>
            </div>
        </div>
    )
};

export default Main;
